<template>
	<div class="page container">
		<div class="page__info">
			<div class="page__info--title">
				Edit focus
			</div>
		</div>

		<div>
			<validation-observer
				data-element="form"
				tag="form"
				ref="form"
				@submit.prevent="submit"
			>
				<input-field
					name="Course focus"
					rules="required"
					type="text"
					v-model="focus.title"
				/>

				<label
					data-element="label"
					for="keywords"
				>
					Keywords
				</label>

				<multi-select
					data-element="multi-select"
					id="keywords"
					name="Keywords"
					v-model="focus.keywords"
					:options="keywords"
					:multiple="true"
					:close-on-select="false"
					:clear-on-select="false"
					:preserve-search="true"
					placeholder="Select one or more options"
					label="title"
					track-by="id"
					:preselect-first="true"
					@select="addKeyword"
					@remove="removeKeyword"
				>
					<template
						slot="selection"
						slot-scope="{ values, search, isOpen }"
					>
						<span
							class="multiselect__single"
							v-if="focus.keywords.length && !isOpen"
						>
							{{ focus.keywords.length }} options selected
						</span>
					</template>
				</multi-select>
				<clickable-list
					:list="focus.keywords"
					@actionClick="editKeyword"
				/>
				<actions
					:actions="getActions"
					@actionClick="onActionClick"
				/>
			</validation-observer>
		</div>
	</div>
</template>

<script>
	import InputField          from '@/components/forms/InputField';
	import Actions             from '@/components/ui/Actions';
	import ClickableList       from '@/components/focusesAndKeywords/ClickableList';
	import actionClick         from '@/mixins/actionClick';
	import invalidFormMessage  from '@/mixins/invalidFormMessage';
	import api                 from '@/services/api';
	import MultiSelect         from 'vue-multiselect';

	export default {
		layout: 'v2default',
		metaInfo: {
			title: 'Edit focus'
		},
		components: {
			ClickableList,
			InputField,
			Actions,
			MultiSelect
		},
		mixins: [actionClick, invalidFormMessage],
		data: () => ({
			focus: {
				title:     '',
				keywords:  []
			},
			keywords:    [],
			newKeyword:  '',
			formLoaded:  false
		}),
		computed: {
			getFocus () {
				if (!this.getFocusId ||
						!this.focus ||
						!this.focus.id) {
					return false;
				}
				return this.focus;
			},
			getFocusTitle () {
				if (!this.getFocus?.title) {
					return false;
				}
				return this.getFocus.title;
			},
			getFocusId () {
				return this.$route.params.focusId;
			},
			getBreadcrumbRoute () {
				return '/admin/focuses/';
			},
			getKeywordRoute () {
				return '/admin/keywords';
			},
			getActions () {
				return {
					primary: [
						{
							text: 'Save',
							type: 'submit'
						},
						{
							text: 'Delete',
							actionId: 'delete'
						}
					]
				};
			},
			getBreadcrumbs () {
				return [
					{
						path: '/admin',
						text: 'Admin'
					},
					{
						path: '/admin/focuses',
						text: 'Focuses'
					},
					{
						path: `/admin/focuses/${this.getFocusId}`,
						text: this.getFocus.title
					}
				].filter(Boolean);
			}
		},
		created: function () {
			this.loadContent();
		},
		methods: {
			async loadContent () {
				try {
					await Promise.all([
						this.setAllKeywords(),
						this.setFocus(),
						this.setKeywords()
					]);
				} catch {
					this.$store.commit('ui/showError');
					return;
				}
				// once everything is loaded
				this.formLoaded = true;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			},
			editKeyword (id) {
				this.$router.push(`${this.getKeywordRoute}/${id}/`);
			},
			async setFocus () {
				const focus = await api.admin.getFocusById({
					focusId: this.getFocusId
				});
				if (!focus) {
					throw new Error();
				}
				this.focus = {
					...this.focus,
					...focus
				};
			},
			async setKeywords () {
				const keywords = await api.admin.getKeywordsById({
					focusId: this.getFocusId
				});
				if (!keywords) {
					throw new Error();
				}
				this.focus.keywords = keywords;
			},
			async setAllKeywords () {
				const keywords = await api.admin.getAllKeywords();
				if (!keywords) {
					throw new Error();
				}
				this.keywords = keywords;
			},
			async addKeyword (option) {
				const keyword = await api.admin.addKeywordToFocusByIdAndFocusId({
					focusId: this.getFocusId,
					keywordId: option.id
				});
				if (!keyword) {
					return false;
				}
				this.$store.commit('ui/showNotification', {
					notification: 'The keyword has been added'
				});
			},
			async removeKeyword (option) {
				const keyword = await api.admin.removeKeywordFromFocusByIdAndFocusId({
					focusId: this.getFocusId,
					keywordId: option.id
				});
				if (!keyword) {
					return false;
				}
				this.$store.commit('ui/showNotification', {
					notification: 'The keyword has been removed'
				});
			},
			async delete () {
				const focus = await api.admin.deleteFocusById({
					focusId: this.getFocusId
				});
				if (!focus) {
					return false;
				}
				await this.$router.push(this.getBreadcrumbRoute);
			},
			async submit () {
				const valid = await this.$refs.form.validate();
				if (!valid) {
					this.showInvalidFormMessage();
					return;
				}
				const focus = await api.admin.updateFocusById({
					focus: { focus: this.focus.title },
					focusId: this.getFocusId
				});
				if (!focus) {
					return false;
				}
				await this.$router.push(this.getBreadcrumbRoute);
				this.$store.commit('ui/showNotification', {
					notification: 'Your focus has been updated'
				});
			}
		}
	};

</script>

<style lang="scss" scoped>
	[data-element='label'] {
		font-size:rem(18);
		padding-bottom:rem(9);
		display: inline-block;
		font-weight: 700;
	}
</style>
